import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function ProjectontwikkelaarPost() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Projectontwikkelaar
            </span>
            <span className="mt-2 pb-6 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Projectontwikkelaar Nieuwhof
            </span>
          </h1>
          <figure>
            <StaticImage
              src="../img/thumbnail_projectontwikkelaar.png"
              alt=""
              className="w-full rounded-lg"
            />
          </figure>
        </div>
        <div className="prose prose-indigo prose-lg text-gray-500 mx-auto">
          <blockquote className="mt-8">
            <p>
              De faalkosten halveren en nieuwe projecten aannemen? Projectontwikkelaar Nieuwhof deed
              het
            </p>
          </blockquote>
          <p className="mt-8 text-xl text-gray-500 font-bold leading-8">
            Projectontwikkelaar Nieuwhof groeide de laatste jaren ontzettend hard. Fijn natuurlijk,
            maar soms ook lastig. Het vroeg om een nieuwe aanpak wat betreft de bedrijfsvoering.
            Herman Nieuwhof zocht naar een app die hem daarmee kon helpen en kwam zo bij Homigo
            terecht.
          </p>

          <p className="mt-8 text-xl text-gray-500 leading-8">
            “De grootste problemen die wij hadden waren in de communicatie. Niet alleen met de
            klant, maar ook met de jongens. Er ging vaak iets mis en sommige afspraken werden niet
            onthouden. We zagen dat we daar nog een grote slag in konden slaan. Zodra we Homigo in
            gebruik namen verminderden onze faalkosten.
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Het was een grote winst om geen discussies achteraf met de klant te hebben, maar ook dat
            de jongens alles konden nalezen in de app. Zo hoefde ik zelf niet 100 vragen per dag te
            beantwoorden en hield ik ruimte over om ons bedrijf te uitbreiden. Ik heb extra
            personeel in dienst kunnen nemen en doordat ik professioneler ben gaan werken kon ik ook
            meer projecten aannemen. Hierdoor maak ik nu meer omzet en winst!”
          </p>

          <h2>Ouderwetse manier</h2>
          <p>
            Lang ging alles best goed op de ouderwetse manier. Veel communicatie verliep via
            papieren documenten. Maar naarmate Nieuwhof groeide, werd steeds duidelijker dat het zo
            niet langer kon.
          </p>
          <p>
            Het kwam regelmatig voor dat de spullen niet klaarstonden of dat mijn jongens veel tijd
            kwijt waren om op zoek te gaan naar de juiste contactpersoon of bijvoorbeeld de
            stroomaansluiting.
          </p>
          <p>
            “Soms waren de jongens wel drie uur niet productief, omdat ze niet de juiste informatie
            hadden. Dat gaat om hele grote bedragen. Daar komt nog bij dat we heel veel via WhatsApp
            communiceerden, en continu aan het zoeken waren naar: welke afspraak hadden we gemaakt,
            wanneer, wie had dat gestuurd, etc.?” We hadden wel honderd losse lijstjes op
            verschillende plekken en eigenlijk was iedereen volledig afhankelijk van wat er in mijn
            hoofd zat. Ik voelde daarom ook veel druk op mij staan.
          </p>
          <h2>Minder faalkosten en meer tijd</h2>
          <p>
            Op kantoor hadden we ook meer rust en overzicht door Homigo. We houden tijd over, omdat
            we minder telefoontjes krijgen en beter georganiseerd zijn. Alles staat georganiseerd in
            Homigo en iedereen weet waar ze aan toe zijn. Als ze dat niet weten, dan kijken ze zelf
            even in de app.
          </p>
          <p>
            We plannen iedereen in en, geven eventuele wijzigingen door in de chatfunctie en zo is
            iedereen altijd op de hoogte.
          </p>
        </div>
      </div>
    </div>
  );
}
