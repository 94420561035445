import React from 'react';
import Layout from '../../components/layout';
import ProjectontwikkelaarPost from '../../components/ProjectontwikkelaarPost';
import BookDemo from '../../components/BookDemo';
import { SEO } from '../../components/SEO/SEO';

const ProjectontwikkelaarPostPage = ({ location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Project ontwikkelaar' }} />
    <ProjectontwikkelaarPost />
    <BookDemo />
  </Layout>
);

export default ProjectontwikkelaarPostPage;
