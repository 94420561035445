import React, { useState } from 'react';
import BookDemoImage from '../img/bookdemo.png';
import BookDemoModal from './BookDemoModal';

export default function BookDemo() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="bg-secondary rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
            <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:pl-20 pr-0">
              <div className="lg:self-center">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                  <span className="block">Meer weten? Vraag hier een gratis demo aan.</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-white">
                  Boek een gratis demo en we laten je zien hoe Homigo jou kan helpen bij jouw
                  werkzaamheden.
                </p>
                <button
                  type="button"
                  onClick={() => setOpen(true)}
                  className="mt-8 bg-primary border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-white hover:bg-yellow-500"
                >
                  Demo aanvragen
                </button>
              </div>
            </div>
            <div className=" aspect-h-3 md:aspect-w-2 md:aspect-h-1">
              <img
                className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16"
                src={BookDemoImage}
                alt="App screenshot"
              />
            </div>
          </div>
        </div>
      </div>
      <BookDemoModal setOpen={setOpen} open={open} />
    </>
  );
}
